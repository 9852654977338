<template>
<div class="d-flex justify-center">
  <v-container class="reloj-fixed">
    <v-row justify="end">
      <v-col cols="12" lg="4">
        <v-card flat :class="[$vuetify.breakpoint.mobile ? 'rounded-b' : 'rounded-0']">
          <v-container class="pa-0">
            <v-row 
              class="white" 
              :style="{'max-height': computedHeight}" v-if="offsetTop < threshold && !$vuetify.breakpoint.mobile">
              <v-col>
                <span class="d-block title body--text">PARTICIPA DE LA SUBASTA:</span>
                <span class="chivo subtitle-1 azul2--text">{{ reloj }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0" v-if="offsetTop < threshold && !$vuetify.breakpoint.mobile">
                <inscribite-button topFlat/>
              </v-col>
            </v-row>
            <v-row 
              v-if="(offsetTop >= threshold - 14) || $vuetify.breakpoint.mobile" 
              :class="
              [
                'white',
                (offsetTop >= threshold - 14) ? 'rounded-b' : '',
                $vuetify.breakpoint.mobile ? 'mobile-fixed rounded-b' : 'dense-fixed mt-0'
              ]" 
              style="max-width:395px;" 
              align="center">
              <v-col class="pa-2" style="position: relative;">
                <span class="body--text chivo pr-12">SUBASTA: <span class="subtitle-1 azul2--text pl-3">{{ reloj }}</span></span>
                <v-btn elevation="0" class="dense-button rounded-l-0 rounded-tr-0" color="turquesa"><img src="assets/ic-arrow-blue-right.svg" class="rotate-arrow-dense" @click="openNewsDialog"/></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
// TODO: Hacer un interval para actualizar la prop datetime con el DateTime actualizado
//        de realizar la diferencia entre hoy y la fecha de subasta
//        Y usar la computed para formatear el texto que se muestra
import EventBus from '@/utils/eventbus'
const fechaSubasta = process.env.VUE_APP_FECHA_SUBASTA
export default {
  name: 'Reloj',
  components: {
    InscribiteButton: () => import('@/views/components/InscribiteButton')
  },
  props: {
    offsetTop: {
      type: Number,
      default: 0
    }
  },
  watch: {
   
  },
  data() {
    return {
      datetime: undefined,
      interval: undefined,
      endTime: undefined,
      threshold: 72
    }
  },
  computed: {
    reloj() {

      /**
       *   Uncomment this when auction date is set
       * 
       *   const days = this.datetime.getDate();
       *   const hours = this.datetime.getHours();
       *   const minutes = this.datetime.getMinutes();
       *   const seconds = this.datetime.getSeconds();
       *   return `${days} d, ${hours} h, ${minutes} m, ${seconds} s`
      */
      return 'PROXIMAMENTE'
    },
    computedHeight() {
      if (!this.offsetTop) return '120px';
      return `${120 - (this.offsetTop + 24)}px`
    }
  },
  methods: {
    openNewsDialog() {
      EventBus.$emit('show-news-dialog',{ title: 'INSCRIBITE', withSoon: true})
    },
    calcDateLeft() {
      const today = new Date()
      this.datetime = new Date(this.endTime - today)
    }
  },
  created() {
    this.endTime = new Date(`${fechaSubasta} 00:00:00`)
    this.datetime = new Date()
    this.interval = setInterval(this.calcDateLeft, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style>
.reloj-fixed {
  position: absolute;
  z-index: 4;
  margin-left: auto;
}
.dense-fixed {
  top: 64px;
  position: fixed;
}
.mobile-fixed {
  position: fixed;
  min-width: 100%;
  left: .8em;
}
.rotate-arrow-dense {
  transform: rotate(0deg);
  max-width: 20px;
}
.dense-button {
  position: absolute;
  right: 0;
  top: 0;
  min-height: 44px;
}
</style>